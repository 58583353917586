<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:32:27
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-17 14:08:25
 * @version: 
 * @Description: 侧边组件
-->


<template>
  <div class="widget widget_ui_posts">
    <h3>{{ siderName }}</h3>
    <ul v-if="dataList.length > 0">
      <li v-for="(item, index) of dataList" :key="index">
        <a :title="item.title" @click="toDetail(item.id)"
          ><span class="thumbnail">
            <img
              :alt="item.titl"
              :src="'https://assets.hzwlb.org' + item.thumb"
              class="thumb"
              style="display: block"
            />
          </span>
          <span class="text">{{ item.title }}</span
          ><span class="muted">{{ item.publish_time }}</span></a
        >
      </li>
    </ul>
    <div class="panel-body" v-else>暂时没有找到</div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    siderName: {
      type: String,
    },
    dataList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail(id) {
      //进入内容详情
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
};
</script>

<style>
</style>