<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:30:05
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-17 14:20:35
 * @version: 
 * @Description: Banner 组件
-->
<template>
  <a-skeleton :loading="loading" :active="loading" :paragraph="{ rows: 4 }">
    <div
      id="focusslide"
      class="
        banner_swiper_container
        swiper-container swiper-container-horizontal
      "
    >
      <swiper class="swiper-wrapper" :options="swiperOption">
        <!-- slides -->
        <swiper-slide v-for="(item, index) of localBanners" :key="index">
          <img
            :alt="item.name"
            class="swiper-lazy img-responsive swiper-lazy-loaded"
            :src="'https://assets.hzwlb.org' + item.image"
          />
        </swiper-slide>
        <!-- Optional controls -->
        <div
          class="swiper-pagination swiper-pagination-clickable"
          slot="pagination"
        ></div>
      </swiper>
    </div>
  </a-skeleton>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "Banner",
  props: {
    banners: {
      type: Array,
    },
    loadingBanner: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: this.loadingBanner,
      localBanners: this.banners,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: true,
        grabCursor: true,
        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  watch: {
    banners() {
      this.localBanners = this.banners;
    },
    loadingBanner() {
      this.loading = this.loadingBanner;
    },
  },
};
</script>
<style>
</style>